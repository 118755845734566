var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"more_msg"},[_c('back-step',{attrs:{"goList":[{
      url: '/',
      name: ("" + (_vm.$t('home.personal.personal.zy')))
    }],"currentTxt":("" + (_vm.$t('home.personal.moreHomePage.xx')))}}),_c('div',{staticClass:"more_box"},[_c('msg-page',{attrs:{"msgList":_vm.masObj.list}}),(_vm.masObj.total > 0)?_c('div',{staticClass:"pagination-box"},[_c('el-pagination',{attrs:{"layout":"total, sizes, prev, pager, next","total":_vm.masObj.total,"current-page":_vm.queryParams.pageNum,"page-size":_vm.queryParams.pageSize},on:{"size-change":function($event){return _vm.changeRouteQuery({
          pageSize:arguments[0],
          pageNum:1
        })},"current-change":function($event){return _vm.changeRouteQuery({
          pageNum:arguments[0]
        })}}})],1):_vm._e()],1),_c('FullLoading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }